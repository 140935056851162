import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import styled, { css } from "styled-components"
import { media } from "../../styles/media"
import { Layout } from "../../components/Layout"
import PageHeaderMedia from "../../components/PageHeaderMedia"
import { FcQuestions } from "react-icons/fc"
import { BiRightArrowAlt } from "react-icons/bi"
import { useLocationData } from "../../hooks/locationHook"

const FAQdiv = styled.div`
  padding: 50px 5%;
  max-width: 1000px;
  margin: 0 auto;
  h4 {
    font-size: 1.32em;
    margin-bottom: 20px;
  }
  .faqSection {
    padding: 30px 0;
    h5 {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      margin-bottom: 0.62em;
      font-weight: bold;
    }
    .answerSection {
      display: flex;
      flex-flow: column nowrap;
      align-items: flex-end;
      p {
        padding: 0.62em 0;
      }
      a {
        background: grey;
        color: white;
        border-radius: 10px;
        padding: 0.62em 1.62em;
      }
    }
  }
`

const BottomLinks = styled.div`
  max-width: 800px;
  margin: 0 auto 100px;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-around;
  a {
    background: ${({ theme }) => theme.colors.primary.green};
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    padding: 0.62em 1.62em;
    border-radius: 5px;
    color: white;
    svg {
      margin-left: 0.62em;
    }
  }
  ${() =>
    media.sp(css`
      a {
        margin: 10px 50px;
      }
    `)}
`

const FaqPage = ({ location }) => {
  const data = useStaticQuery(query)
  useLocationData(location.pathname)

  return (
    <Layout>
      <PageHeaderMedia
        fluid={data.zoo.faqMedia.childImageSharp.fluid}
        pageTitles={data.zoo.faqTitle}
        pageText={data.zoo.faqSubtitle}
      />
      <FAQdiv>
        {data.zoo.faqTexts.map(dog => (
          <div className="faqSection">
            <h5>
              <FcQuestions />
              {dog.questionText}
            </h5>
            <div
              className="answerSection"
              dangerouslySetInnerHTML={{ __html: dog.answerText }}
            />
          </div>
        ))}
      </FAQdiv>
      <BottomLinks>
        <Link to="/inquiry/">
          お問合せに戻る
          <BiRightArrowAlt />
        </Link>
        <a
          href="https://www.francois.co.jp/inquiry"
          target="_blank"
          rel="noopener noreferrer"
        >
          商品に関するお問合せ
          <BiRightArrowAlt />
        </a>
      </BottomLinks>
    </Layout>
  )
}

export const query = graphql`
  {
    zoo: strapiFaqPage {
      faqTitle
      faqSubtitle
      faqMedia {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      faqTexts {
        id
        questionText
        answerText
      }
    }
  }
`

export default FaqPage
